<template>
  <a-card :bordered="false">

    <div class="table-page-search-wrapper">
      <a-form layout="inline" :form="form">
        <a-row :gutter="8">
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="开始时间">-->
<!--              <a-input v-model="queryParam.startTime" placeholder=""/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="结束时间">-->
<!--              <a-input v-model="queryParam.endTime" placeholder=""/>-->
<!--            </a-form-item>-->
<!--          </a-col>-->
          <a-col :md="5" :sm="24">
            <a-form-item label="单号">
              <a-input v-model="queryParam.orderSn" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="类型">
              <a-select  @change="changeType" placeholder="请选择"  v-decorator="['queryParam.type']">
                <a-select-option value="">全部</a-select-option>
                <a-select-option key="10" value="10">待接单</a-select-option>
                <a-select-option key="13" value="13">二次确认</a-select-option>
                <a-select-option key="20" value="20">跟进</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="已读状态">
              <a-select @change="changeReaded" placeholder="请选择"  v-decorator="['queryParam.readed']">
                <a-select-option key="" value="">全部</a-select-option>
                <a-select-option key="0" value="0">未读</a-select-option>
                <a-select-option key="1" value="1">已读</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 6 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="clean">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
<!--      <a-button type="primary" icon="plus" @click="handleAdd()" >新建</a-button>-->
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content',y:scrollHeight}"
    >
      <span slot="remark" slot-scope="text">
        <ellipsis :length="20" tooltip>{{ text }}</ellipsis>
      </span>
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record" >
          <template>
            <a @click="handleEdit(record)" v-action:but_readed v-show="record.readed!=1">已读</a>
          </template>
        </span>

    </s-table>



  </a-card>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode, defineComponent } from 'vue';
import { Modal } from 'ant-design-vue';
import {
  deleteRole,
  findPageMessage,
  updateMessage
} from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
  },
  data () {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: {pageSizeOptions: ['50', '100','200','500']},
      treeData:[],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '标题',
          width: '15%',
          key: 'title',
          dataIndex: 'title',
          align: 'center',
        },
        {
          title: '内容',
          width: '30%',
          key: 'content',
          dataIndex: 'content',
          align: 'center',
        },
        {
          title: '来源',
          width: '10%',
          key: 'source',
          dataIndex: 'source',
          align: 'center',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '服务商平台'
            }else if(text==2){
              ret = '中心平台'
            }else{
              ret = '三方平台'
            }
            return ret
          }
        },
        {
          title: '备注',
          width: '20%',
          key: 'remark',
          align: 'center',
          dataIndex: 'remark',
          scopedSlots: { customRender: 'remark' },
        },
        {
          title: '是否已读',
          width: '10%',
          key: 'readed',
          dataIndex: 'readed',
          align: 'center',
          customRender: (text) => {
            let ret = ""
            if(text==1){
              ret = '已读'
            }else{
              ret = '未读'
            }
            return ret
          }
        },
        // {
        //   title: '状态',
        //   width: '50px',
        //   key:'status',
        //   dataIndex:'status',
        //   customRender: (text) => {
        //     let ret = ""
        //     if(text==1){
        //       ret = '正常'
        //     }else{
        //       ret = '作废'
        //     }
        //     return ret
        //   }
        // },
        {
          title: '',
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '150px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        let param = {};
        param = Object.assign(parameter, this.queryParam)

        let qtype = this.$route.params.type
        let qreaded = this.$route.params.readed
        console.info("默认传参：type："+qtype+",readed:"+qreaded)
        if(param.type==null&&qtype!=null && param.readed==null&&qreaded!=null){
          param.type = qtype
          param.readed = qreaded
        }

        return findPageMessage(param)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      confirmLoading:false,
      form: this.$form.createForm(this),
      tabObj:{},//当前对象，编辑是用到
      scrollHeight:0
    }
  },
  created () {
    this.tableOption();
    this.scrollHeight = document.body.scrollHeight-400;
    // if(this.$route.query.type=="10"){
    //   this.queryParam.type = "10";
    //   this.queryParam.readed = "0";
    // }else if(this.$route.query.type=="20"){
    //   this.queryParam.type = "20";
    //   this.queryParam.readed = "0";
    // }
  },
  // mounted() {
  //   this.form.setFieldsValue({
  //     "queryParam.type": this.queryParam.type,
  //     "queryParam.readed": this.queryParam.readed
  //   })
  //   this.$refs.table.refresh(true)
  // },
  activated() {
    let qtype = this.$route.params.type
    let qreaded = this.$route.params.readed
    console.info("默认传参：type："+qtype+",readed:"+qreaded)
    if(qtype!=null&&qreaded!=null){
      this.form.setFieldsValue({
        "queryParam.type":qtype+"",
        "queryParam.readed":qreaded+"",
      })
    }else{
      this.form.setFieldsValue({
        "queryParam.type":"",
        "queryParam.readed":"",
      })
    }
    this.$refs.table.refresh(true)
  },
  filters: {
  },
  methods: {
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleEdit (record) {
      let that = this
      Modal.confirm({
        title: '确认已读',
        // icon: createVNode(ExclamationCircleOutlined),
        content: '确认已读吗',
        onOk() {
          return new Promise((resolve, reject) => {
            // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            updateMessage({id:record.id,readed:1})
                .then(res => {
                  that.$message.success("更新成功")
                  that.$refs.table.refresh()
                })
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    handleDelRole (record) {
    },
    handleOk () {

    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    addRoleHandleCancel () {
    },
    handleAdd () {
    },
    handleSubmit(e){
      e.preventDefault()

    },
    addRoleCancel(){
    },
    iscandel(record){
    },
    bindpermHandleCancel(){
    },
    handleBind(e){
    },
    clean(){
      this.queryParam={}
      this.form.setFieldsValue({
        "queryParam.type" : undefined,
        "queryParam.readed" : undefined,
        "queryParam.orderSn" : undefined,
      })
    },
    changeType(e){
      console.info("--当前选中："+e)
      this.queryParam.type = e
      this.form.setFieldsValue({
        "queryParam.type":e
      })
    },
    changeReaded(e){
      console.info("--当前选中："+e)
      this.queryParam.readed = e
      this.form.setFieldsValue({
        "queryParam.readed":e
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true)
    next()
  },
}
</script>
<style lang="less" scoped>
.table-operator{
  margin-bottom: 10px;
}
</style>